<template>
	<div>
		<div class="header-section icon-list detailed" v-if="loaded">
			<div class="header-with-tabs row-space-tbf">
            	<div class="space-left"></div>
                <div class="content">
					<slot />
                    <div v-if="sortedImprovements.length && $resize && $mq.above(1024) && soft_procedures.is_active" class="actions">
                    	<button class="btn-tbf blue center" v-bind:class="{'only-icon': $resize && $mq.below(1299)}" @click="donwloadXLS">
                            <span class="text" v-if="$resize && $mq.above(1300)">{{ $t('reports.download_xls') }}</span>
                            <icon-download class="icon" v-else />
                        </button>
                    </div>
                </div>
                <div class="space-right"></div>
            </div>
		</div>

		<template v-if="soft_procedures.is_active">
			<template v-if="loadedList">
				<div class="data-list list-goals" v-if="sortedImprovements.length">
					<div class="row-space-tbf header-list">
						<div class="space-left"></div>
						<div class="content">
							<div class="column-filter sortable column-name" @click="sortList('entity_name')" v-bind:class="[sortBy == 'entity_name' ? 'sort ' + sortDirection : '']">
								<div class="text">{{ $t('improvements.name') }}</div>
								<div class="icon-filter"><icon-arrow /></div>
							</div>
							<div v-if="$resize && $mq.above(1165)" class="column-filter column-status">
								<div class="text">{{ $t('improvements.status.name') }}</div>
							</div>
							<div v-if="$resize && $mq.above(1055)" class="column-filter column-what-asked">
								<div class="text">{{ $t('improvements.what_asked') }}</div>
							</div>
							<div v-if="$resize && $mq.above(480)" class="column-filter column-who-asked">
								<div class="text">{{ $t('improvements.who_asked') }}</div>
							</div>
							<div v-if="$resize && $mq.above(850)" class="column-filter column-date">
								<div class="text">{{ $t('improvements.date') }}</div>
							</div>
							<div class="column-filter column-user"></div>
						</div>
						<div class="space-right"></div>
					</div>

					<template v-for="improvement in sortedImprovements">
						<div class="row-space-tbf row-list-item" :key="improvement.id">
							<div class="space-left"><icon-question /></div>
							<div class="content">
								<div class="border-hover"></div>
								<div class="column-name-desc column-name" @click="viewImprovements(improvement.slug, improvement.type)">
									<div class="name">{{improvement.entity_name}}</div>
								</div>
								<div v-if="$resize && $mq.above(1165)" class="column-simple-text column-status">
									<div class="text">{{ $t('improvements.status.' + improvement.status) }}</div>
								</div>
								<div v-if="$resize && $mq.above(1055)" class="column-simple-text column-what-asked">
									<v-popover class="body" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
										<div class="text body">
											<template >
												<span>{{ improvement.body }}</span>
											</template>
										</div>
										<template slot="popover">
											<div class="simple-text"><span>{{ improvement.body }}</span></div>
										</template>
									</v-popover>
								</div>
								<div v-if="$resize && $mq.above(480)" class="column-simple-text column-who-asked">
									<div class="text">{{ improvement.user.name }}</div>
								</div>
								<div v-if="$resize && $mq.above(850)" class="column-simple-text column-date">
									<div class="text">{{improvement.created_at | moment('DD MMM YYYY')}}</div>
								</div>
								<div class="column-avatar column-user">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
										<img :src="improvement.user.avatar" v-if="improvement.user && improvement.user.avatar">
										<div class="user-circle" v-else>
											<icon-user />
										</div>
										<template slot="popover">
											<div class="simple-text">{{ improvement.user.name }}</div>
										</template>
									</v-popover>
								</div>
							</div>
							<div class="space-right">
							</div>
						</div>
					</template>
				</div>
				<div class="row-space-tbf list-empty" v-else>
					<div class="space-left"></div>
					<div class="content full">
						<!-- <div class="icon-empty">
							<img src="/build/images/no-results-found.svg">
						</div> -->
						<div class="title">{{ $t('empty.report-title')}}</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>
			<loader-items-list v-else/>

			<infinite-loading :identifier="1" @infinite="infiniteImprovements" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script>
	import NotModule from '@/components/General/NotModule'
    import IconArrow from '../../Icons/Arrow'
    import IconUser from '../../Icons/User'
    import IconPeople from '../../Icons/People'
    import IconEdit from '../../Icons/EditDots'
	import IconQuestion from '../../Icons/Question'	
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import LoaderHeaderList from '../../PagesLoaders/HeaderList'
	import InfiniteLoading from 'vue-infinite-loading'
	import IconDownload from '../../Icons/Download'

    export default {
    	components: {
			NotModule,
            IconArrow,
            IconUser,
            IconPeople,
            IconEdit,
			IconQuestion,
            LoaderItemsList,
            LoaderHeaderList,
            IconDownload,
			InfiniteLoading
        },
        data() {
            return {
            	loaded: true,
            	loadedList: false,
            	improvements: [],
            	sortBy: '',
            	sortDirection: 'asc',
				view_more: 0,
				counter: 0,
            }
        },
        async mounted(){
			if( this.soft_procedures.is_active ) {
				this.$refs.infiniteLoading.status = 1
				this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			}
        },
        computed: {
            sortedImprovements: function(){
                return this.improvements.sort((p1,p2) => {
                    let modifier = 1;
                    if(this.sortDirection === 'desc') modifier = -1;
                    if(p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier; if(p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
                    return 0;
                });
            },
			soft_procedures() {
				return this.$store.getters['applications/getApplication']('Proceduri');
			}
        },
        methods: {
			viewImprovements(slug, type){
				this.$router.push({ name: type + '-improvements', params: {slug: slug} })	
			},
			infiniteImprovements($state) {
				axios.get('/reports/' + this.$auth.user().instance.id + '/improvement-proposals', {params: {view_more: this.view_more }})
				.then(({data}) => {
                    if(data.data.length) {
                        this.counter++
						this.view_more = 10 * this.counter
						this.improvements.push(...data.data)

						setTimeout(() => { $state.loaded() }, 200)
        				if(data.data.length < 10){
        					$state.complete()
        				}
                    } else{
						$state.complete()
					}

        			this.loadedList = true
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			donwloadXLS() {
				var showColumnsObj = ['entity_name', 'status', 'body', 'user.name', 'created_at']

				axios.get('/reports/' + this.$auth.user().instance.id + '/improvement-proposals', 
				{
					params : {type: 'xls', show_columns: showColumnsObj},
                	responseType: 'blob'
				})
				.then((response) => {

					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.$t('reports.improvement_requests') + '.xlsx';

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
			}
        }
    }
</script>

<style lang="scss" scoped>
	.list-goals{
		.view-more{
            width: 100%;
            a{
                width: 100%;
                height: 38px;
                .text{
                    color: $grey;
                }
            }
        }
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-name{
			flex: 1 1 auto;
		}
		.column-date,
		.column-what-asked,
		.column-status{
			flex: 0 0 130px;
		}
		.column-who-asked {
			flex: 0 0 130px;
		}
		.column-what-asked > div > div > div.text.body {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 110px;
		}
		@media (max-width: 1050px) {
			.column-date,
			.column-status,
			.column-who-asked{
				flex: 0 0 100px;
			}
			.column-what-asked{
				flex: 0 0 120px;
			}
			.column-user{
				flex: 0 0 37px;
			}
		}
	}
	.list-empty .content {
		padding: 10px;
		padding-left: 0px;
		border-top: none;
		color: $grey
	}
	
</style>